/** Swiper styles **/

.swiper {
    user-select: none;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    height: 200px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.swiper-slide {
    display: block;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #fff;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 1);
    border: 3px solid #fff;
    border-radius: 12px;
}

.swiper-slide-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    /*padding: 24px 24px;*/
    
    align-items: flex-start;
    justify-content: flex-end;
   
}

.swiper-slide-title {
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 0.03em;
    color: rgba(255, 255, 255, 1);
}

.swiper-slide-image {
    position: absolute;
    object-fit: cover;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    z-index: 0;
}

@media (min-width: 768px) {
    .swiper {
        height: 300px;
    }

    .swiper-slide {
        /*    width: 85%;
    height: 100%;
    border-radius: 8px;*/
    }

    .swiper-slide-content {
        /* padding: 24px 24px;*/
    }
}

.base-tile {
    background-color: azure;
    position: absolute;
    object-fit: cover;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    z-index: 0;
}


.box {
    display: flex;
    flex-flow: column;
    height: 100%;
    
}

    .box .arow.content {
        flex: 1 1 300px;
    }

    .box .arow.footer {
        flex: 0 0 40px;
        height: 40px;
        background-color: white;
    }

    .box .arow.item {
        flex: 1 1 20px;
    }



.innerbox {
    display: flex;
    flex-flow: row;
    width: 100%;
    height: 100%;
}

    .innerbox .column.lft {
        flex: 2 1 200px;
    }

    .innerbox .column.rgt {
        flex: 1 1 100px;
        
    }


/*@media (min-width: 1024px) {
    .innerbox .column.lft {
        flex: 3 1 300px;
        background-color: blue;
    }

    .innerbox .column.rgt {
        flex: 2 1 200px;
        background-color: blueviolet;
    }
}*/

.tileimg {
    object-fit: cover;
    position: relative;
    width: 100%;
    height: 0;
    min-height: 100%;
    /*border: 2px solid black;*/
}

.leftborder {
    border-left: 3px solid white;
}

.topborder {
    border-top: 3px solid white;
}

.textContainer {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    height: 100%;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    gap: 10px;
    flex-wrap: wrap;
    overflow: hidden;
}
    .textContainer .column {
        flex: 1 1 0px;
    }

.tiletext {
    color: black;
    font-size: 18px;
    justify-content: center;
    margin:unset;
    height:40px;
    white-space:nowrap;
}

.full {
    transition: opacity 400ms ease 0ms;
}

.thumb {
    filter: blur(20px);
    transform: scale(1.1);
    transition: visibility 0ms ease 400ms;
}