a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html { font-size: 13px; }

@media (min-width: 768px) {
    html { font-size: 16px; }
}


