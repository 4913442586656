/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}


#root {
    height: 100%;
    width:100%;
    position:fixed;
    overflow: hidden;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.container {
    max-width: 6000px;
}

.removeMapBoxPopupPadding .mapboxgl-popup-content {
    padding: 0px !important;
}

.maxWidth600 {
    max-width: 600px !important;
}

.MuiButton-label{
    color:white;
}

.mapboxgl-popup {
    max-width: 300px;
    font: 10px/16px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    overflow-wrap: break-word;
}



.trans .maplibregl-popup-content {
    background: rgba(255, 255, 255, 0.5) !important;
    min-width: 400px !important;
    min-height: 400px !important;
    max-width: 600px !important;
    max-height: 600px !important;
}

.trans .maplibregl-popup-tip {
    background: rgba(255, 255, 255, 0.5) !important;
    visibility:hidden;
}

.contentContainer {
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
    height: 100%;
}

.fullHeight {
    height: 100%;
    position: relative;
}

.map-container {
    position: relative;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.abs {
    font: 15px/24px monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    pointer-events: none;
    height: 100%;
}
@media screen and (min-width: 600px) {
    .fixer {
        font: 15px/24px monospace;
        z-index: 1;
        position: fixed;
        top: 48px;
        pointer-events: none;
        height: 100%;
    }
}

@media screen and (max-width: 599px) {
    .fixer {
        font: 15px/24px monospace;
        z-index: 1;
        position: fixed;
        top: 32px;
        pointer-events: none;
        height: 100%;
    }
}

    .right {
        right: 0;
    }

    .left {
        left: 0;
    }

    .sidebar {
        background-color: rgba(35, 55, 75, 0.9);
        color: #ffffff;
        position: relative;
        padding: 6px 12px;
        margin: 12px;
        border-radius: 4px;
        width: max-content;
        /*pointer-events: auto*/
    }

    .debug {
        background-color: rgba(35, 55, 75, 0.9);
        color: #ffffff;
        padding: 6px 12px;
        margin: 12px;
        border-radius: 4px;
        /*pointer-events: auto*/
        position: absolute;
        width: max-content;
        bottom: 70px;
    }

    .filterbar {
        background-color: rgba(35, 55, 75, 0.9);
        color: #ffffff;
        width: max-content;
        position: relative;
        padding: 6px 12px;
        margin: 12px;
        border-radius: 4px;
        font: 15px/24px monospace;
        pointer-events: auto;

    }
