


.mySwiper2X {
    height: auto !important;
    width: 100%;
}

    .mySwiper2X.swiper-slide {
        background-size: cover;
        background-position: center;
    }

.mySwiperX {
    /*height: 15vh !important;*/
    height: 100%;
    box-sizing: border-box;
    padding: 10px 0;
    margin-left: auto;
    margin-right: auto;
}

    .mySwiperX .swiper-slide {
        /* background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        object-position: center;*/
        background-size: cover;
        background-position: center;
        /*height: 100%;*/
        opacity: 0.4;
    }

    .mySwiperX .swiper-slide-thumb-active {
        opacity: 1;
    }

    .mySwiperX .swiper-slide img {
        display: block;
        width: 100%;
        /*height: 100%;*/
        object-fit: cover;
    }

.propMainImg {
    object-fit: cover;
    position: relative;
    width: 100%;
    min-height: 100%;
}

.propThumbMainImg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}